import React, { useMemo } from 'react';
import { css } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { ConfluenceIcon, ConfluenceLogo } from '@atlaskit/logo';

import { NavLogo } from '@atlassian/navigation-system/top-nav';

import { useRouteDataRef } from '@confluence/route-manager';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { WIKI } from '@confluence/named-routes';

import type { TopNavigationQuery_siteConfiguration as SiteConfiguration } from './__types__/TopNavigationQuery';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.fireLogoClickedAnalytics,
);

const customLogo = css({
	width: '28px',
	height: '28px',
});

type SiteLogoButtonProps = {
	siteConfiguration: SiteConfiguration | undefined;
};

export const SiteLogoButton = ({ siteConfiguration }: SiteLogoButtonProps) => {
	const routeDataRef = useRouteDataRef();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const href = WIKI.toUrl();
	const onClick = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef.current?.routeName);
	const showSiteTitle = siteConfiguration?.showSiteTitle;
	const siteTitle = showSiteTitle ? siteConfiguration?.siteTitle : undefined;

	const CustomNavLogo = useMemo(() => {
		if (siteConfiguration?.customSiteLogo) {
			// eslint-disable-next-line jsx-a11y/alt-text
			return () => <img src={siteConfiguration.siteLogoUrl} css={customLogo} />;
		}

		return null;
	}, [siteConfiguration?.customSiteLogo, siteConfiguration?.siteLogoUrl]);

	if (CustomNavLogo) {
		return (
			<NavLogo
				label={siteTitle ?? 'Confluence'}
				href={href}
				logo={CustomNavLogo}
				icon={CustomNavLogo}
				onClick={onClick}
			/>
		);
	}

	return (
		<NavLogo
			label="Confluence"
			href={href}
			logo={ConfluenceLogo}
			icon={ConfluenceIcon}
			onClick={onClick}
		/>
	);
};
