import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { useLegacySearchTheme } from '@atlassian/navigation-system/experimental/use-legacy-search-theme';

import { fg } from '@confluence/feature-gating';
import { NavigationSearchDialog, QuickFind } from '@confluence/navigation-search-dialog';

export const SearchBar = () => {
	const theme = useLegacySearchTheme();
	const isQuickFindEnabled = fg('quick_find_refactor') && !fg('confluence_rovo_search_opt_out');

	return (
		<Box xcss={[searchBarWrapper, isQuickFindEnabled && flexStyles]}>
			{isQuickFindEnabled ? (
				<QuickFind />
			) : (
				<NavigationSearchDialog shouldFillContainer theme={theme} />
			)}
		</Box>
	);
};

const searchBarWrapper = xcss({
	position: 'relative',
	width: '100%',
	maxWidth: '780px',
});

const flexStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});
